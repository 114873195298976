<template>
    <div id="EventConfigAccountGroup">
        <v-container fluid app>
            <ZnapTable ref="znapTable"
                :endpoint="endpoint"
                :options="options"
                :tableRows="tableRows"
                :noData="noData"
                @setNoData="setNoData"
                :noDataMessage="noDataMessage"
                @setNoDataMessage="setNoDataMessage"
                :filterConditions="filterConditions"
                @setFilterConditions="setFilterConditions"
                @clearFilters="setFilterConditions"
                :loadTable="loadTable"
                @setLoadTable="setLoadTable"
                @clearNotRequiredOptionsItems="clearNotRequiredOptionsItems"
            >
                <!-- // Filters -->
                <template v-slot:filters>
                    <ZnapFilters ref="znapFilters"
                        :filterComponents="options"
                        :noData="noData"
                        @setSelection="populateFilterConditions"
                        :clearFilters="clearFilters"
                        @setClearFilters="setClearFilters"

                        @setFilterValue="setFilterValue"
                        @clearFilteredBy="clearFilteredBy"
                    >
                    </ZnapFilters>
                </template>
            </ZnapTable>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue'
import ZnapTable from '@/components/znap/ZnapTable.vue'
import ZnapFilters from '@/components/znap/Filters.vue'

export default {
    name:'EventConfigAccountGroup',

    components: { ZnapTable, ZnapFilters },

    computed: {
        endpoint() {
            return [
                this.$ipEvent,
                'event-config-account-group',
                {
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_module',
                            operator: '=',
                            value: 6
                        }
                    ]
                }
            ]
        },
    },

    data: () => ({
        tableRows: 10000,

        options: [
            {
                column: 'id_event',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Evento',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipEvent, 'event' ],
                conditions: [
                    {
                        AndOr: 'AND',
                        column: 'id_module',
                        operator: '=',
                        value: 6
                    }
                ]
            },
            {
                column: 'id_account_group_version',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Versão das contas gerenciais',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipAccount, 'account-group-version' ],
                conditions: [
                    {
                        AndOr: 'AND',
                        column: 'id_module',
                        operator: '=',
                        value: 6
                    }
                ]
            },
            {
                column: 'id_account_group',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Contas gerenciais',
                required: false,
                filterable: true,
                endpoint: [ Vue.prototype.$ipAccount, 'account-group' ],
                conditions: [
                    {
                        AndOr: 'AND',
                        column: 'id_module',
                        operator: '=',
                        value: 6
                    }
                ]
            },
            {
                column: 'id_module_event_ref',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Módulo do evento',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipSecurity, 'module' ],
                filters: ['id_event_ref'],
            },
            {
                column: 'id_event_ref',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Evento de referência',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipEvent, 'event-ref' ],
                filteredBy: ['id_module_event_ref'],
                filteredItems: [],
            },
            {
                column: 'id_module_ref',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Módulo da versão da conta gerencial',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipSecurity, 'module-ref' ],
                filters: ['id_account_group_version_ref'],
            },
            {
                column: 'id_account_group_version_ref',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Versão das contas gerenciais de referência',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipAccount, 'account-group-version-ref' ],
                filters: ['id_account_group_ref'],
                filteredBy: ['id_module_ref'],
                filteredItems: [],
            },
            {
                column: 'id_account_group_ref',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Contas gerenciais de referência',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipAccount, 'account-group-ref' ],
                filteredBy: ['id_account_group_version_ref'],
                filteredItems: [],
            },
            {
                column: 'id_chart_account',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Conta contábil',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipOrganization, 'chart-account' ]
            },
            {
                column: 'id_purpose',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Propósitos',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipOrganization, 'purpose' ]
            },
            {
                column: 'id_cost_center',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Centros de custo',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipOrganization, 'cost-center' ]
            },
            {
                column: 'id_planning_group',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Grupo de planejamento',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipApprovalFlow, 'planning-group' ]
            },
            {
                column: 'id_sales_channel',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Canais de venda',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipSales, 'sales-channel' ]
            },
            {
                column: 'id_sales_force',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Equipe de vendas',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipSales, 'sales-force' ]
            },
            {
                column: 'id_customer_group',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Grupo de clientes',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipCustomer, 'customer-group' ]
            },
            {
                column: 'id_hr_classification',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Classificação RH',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipOrganization, 'hr-classification' ]
            },
            {
                column: 'id_status',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Status',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipUnit, 'status' ]
            },
            {
                column: 'id_cost_center_classification',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Classificação do centro de custo',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipOrganization, 'cost-center-classification' ]
            },
            {
                column: 'id_account_classification',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Classificação das contas',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipAccount, 'account-classification' ]
            },
        ],

        noDataMessage: '',
        noData: true,

        // Filters
        filterConditions: [],
        loadTable: false,
        clearFilters: false,
    }),

    methods: {
        async setFilterValue(e) {
            e.filters.forEach(async f => {
                let option = this.options.find(o => o.column === f)
                if (option) {
                    if (e.value.selected) {
                        let filter = {
                            conditions: [
                                {
                                    AndOr: 'AND',
                                    column: e.value.column,
                                    operator: '=',
                                    value: e.value.selected.id
                                }
                            ]
                        }
    
                        try {
                            const res = await this.$http.post(option.endpoint[0] + option.endpoint[1] + '/list-options', { filter })
                            if (res) {
                                option.filteredItems = res.data.rows
                            }
                        } catch (err) {
                            this.$fnError(err)
                        }
                    } else {
                        option.filteredItems = []
                    }

                }
            })
        },

        clearFilteredBy() {
            this.options.forEach(async o => {
                if (o.filteredBy) {
                    o.filteredItems = []
                }
            })
        },

        populateFilterConditions(selectionArray, requiredFilter) {
            this.filterConditions = []

            if (requiredFilter) {
                this.filterConditions.push({
                    AndOr: 'AND',
                    column: selectionArray[0].column,
                    operator: '=',
                    value: selectionArray[0].id,
                    required: true
                })

                this.loadTable = true
            } else {
                if (selectionArray.length !== 0) {
                    selectionArray.forEach(selection => {
                        
                        let required = false
                        this.options.forEach(option => {    
                            if (option.required && selection.column === option.column) {
                                required = true
                            }
                        })

                        if (selection.id === undefined) {
                            this.filterConditions = []
                        } else {
                            if (selection.id.length > 1) {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: 'IN',
                                    value: selection.id,
                                    required
                                })
                            } else {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: '=',
                                    value: selection.id,
                                    required
                                })
                            }             
                        }
                    })
                }
            }
        },

        setNoDataMessage(message) {
            this.noDataMessage = message
        },

        setNoData(payload) {
            this.noData = payload
        },

        setFilterConditions(array) {
            this.filterConditions = array
            
            if (array.length === 0) {
                this.clearFilters = true
            }

            this.options.forEach(option => {
                if (option.required) {
                    this.loadTable = true
                } 
            })
        },

        setClearFilters(payload) {
            this.clearFilters = payload
        },

        clearNotRequiredOptionsItems() {
            this.options.forEach(option => {
                if (!option.required) {
                    option.items = []
                } 
            })
        },

        setLoadTable(payload) {
            this.loadTable = payload
        }
    },
}
</script>

<style>
</style>